import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import './App.css';
import AllPropositions from './Pages/AllPropositions';
import CreatePropositionForm from './Pages/CreatePropositionForm';
import MainPage from './Pages/MainPage';
import mmaLandingPage from './Pages/LandingPages/mmaLandingPage/mmaLandingPage';
import PartnersLandingPage from './Pages/LandingPages/PartnersLandingPage/PartnersLandingPage';
import Proposition from './Pages/Proposition';
import OpenHouseLandingPage from './Pages/LandingPages/OpenHouseLandingPage/OpenHouseLandingPage';
import ThankYouPage from './Pages/LandingPages/OpenHouseLandingPage/ThankYouPage';

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/lp/partners" component={PartnersLandingPage} />
        <Route exact path="/lp/mma" component={mmaLandingPage} />
        <Route exact path="/lp/openhouse" component={OpenHouseLandingPage} />
        <Route exact path="/lp/openhouse/thankyou" component={ThankYouPage} />
        <Route exact path="/propostas" component={AllPropositions} />
        <Route exact path="/propostas/criar" component={CreatePropositionForm} />
        <Route exact path="/propostas/:id" render={({ match: { params: { id } } }) => <Proposition id={id} />} />
      </Switch>
    </HashRouter>
  );
}

export default App;
