/* eslint-disable react/destructuring-assignment */
import React from 'react';

function BtnComponent() {
  function handleBtnClick() {
    const form = document.getElementById('form');
    form.scrollIntoView();
  }
  return (
    <button onClick={handleBtnClick} className="headerHelpButton middlePageHelpButton" id="helpButtonContent" type="button">Diga como podemos ajudar</button>
  );
}

export default BtnComponent;
