/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Typed from 'typed.js';
import BtnComponent from './BtnComponent';

function PageFirstContent() {
  const el = React.useRef(null);
  const typed = React.useRef(null);

  React.useEffect(() => {
    const options = {
      strings: [
        'Performance',
        'Branding',
        'Vendas',
        'Negócios',
      ],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    };

    typed.current = new Typed(el.current, options);

    typed.current.start();

    return () => {
      typed.current.destroy();
    };
  }, []);

  return (
    <div className="firstContent">
      <div className="firstContentDiv">
        <p>
          Trabalhamos com dados e criatividades para
        </p>
        <h1 className="wrapper">
          <div className="static-txt">Escalar</div>
          <div className="dynamic-txts">
            <div id="getElement" className="animationTrigger1">
              <span ref={el}>
                {}
              </span>
            </div>
          </div>
        </h1>
        <p>
          Somos uma agência de marketing digital e dados de performance.
          Usamos dados e estratégias de marketing para melhorar os resultados de
          nossos clientes em vendas e alcance.
        </p>
      </div>
      <BtnComponent />
    </div>
  );
}

export default PageFirstContent;
