import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import * as arrowAnimation from '../Media/arrowAnimations.json';
import KaizenGreenLogo from '../Media/KaizenGreenLogo.svg';
import adventures from '../Media/Proposition/adventures.png';
import user from '../Media/Proposition/user.gif';
import responsividade from '../Media/Proposition/responsividade.gif';
import trustTheData from '../Media/Proposition/trustTheData.gif';
import siteErau from '../Media/Proposition/siteErau.png';
import siteKaizen from '../Media/Proposition/siteKaizen.png';

function Proposition(props) {
  const { id } = props;
  const [endFetch, finishFetch] = useState(false);
  const [fetchData, updateFetchData] = useState([]);
  const [entregaveis, changeEntregaveis] = useState([]);

  async function executeFetch() {
    const data = await fetch(`https://stark-ridge-24747.herokuapp.com/propositions/${id}`)
      .then((result) => result.json()).then((result) => {
        const newDelivers = result.entregaveis.split(', ');
        changeEntregaveis(newDelivers);
        return result;
      });
    updateFetchData(data);
    finishFetch(true);
  }

  useEffect(() => {
    executeFetch();
  }, [endFetch]);

  return (
    <div className="propositionDiv">
      <div className="propositionStartDiv">
        <img className="propositionKaizenLogo" src={KaizenGreenLogo} alt="Logo verde da Kaizen" />
        <Lottie
          options={{ loop: true, autoplay: true, animationData: arrowAnimation }}
          height={100}
          width={100}
        />
      </div>
      <div className="adventuresDiv">
        <img alt="Adventures Partners Logo" src={adventures} />
      </div>
      <div className="webdesignPrinciples">
        <h1>Nossos princípios em webdesign</h1>
        <div className="webdesignPrinciple">
          <div className="webdesignPrincipleText">
            <h3>
              #1 - Usuário no
            </h3>
            <h3>
              centro de tudo
            </h3>
          </div>
          <img alt="Imagem para representar o primeiro principio" src={user} />
        </div>
        <div className="webdesignPrinciple">
          <div className="webdesignPrincipleText">
            <h3>
              #2 - Responsividade
            </h3>
            <h3>
              e performance
            </h3>
          </div>
          <img alt="Imagem para representar o segundo principio" src={responsividade} />
        </div>
        <div className="webdesignPrinciple">
          <div className="webdesignPrincipleText">
            <h3>
              #3 - Trust the data!!
            </h3>
          </div>
          <img alt="Imagem para representar o terceiro principio" src={trustTheData} />
        </div>
      </div>
      <div className="webdesignProjectsDiv">
        <h1>Projetos de Webdesign</h1>
        <div className="webdesignProject">
          <h3>
            #1 - Site Institucional ERAU
          </h3>
          <p>
            Projeto realizado em conjunto com a Embry-Riddle Aeronautical University,
            para construção de site institucional e implementação de CMS
          </p>
          <a href="https://erau.edu">Clique aqui para acessar</a>
          <img src={siteErau} alt="Imagem para representar o primeiro projeto" />
        </div>
        <div className="webdesignProject">
          <h3>
            #2 - Site Institucional Kaizen Performance
          </h3>
          <p>
            Projeto realizado internamente em Webflow para design responsivo.
            Em adição, blog em Wordpress em subdomínio.
            Integração e automação com pipedrive e chatbot
          </p>
          <a href="https://kaizenperformance.io/#/">Clique aqui para acessar o site</a>
          <a href="https://blog.kaizenperformance.io/">Clique aqui para acessar o blog</a>
          <img src={siteKaizen} alt="Imagem para representar o segundo projeto" />
        </div>
      </div>
      <div className="proposition">
        <div className="propositionHeaders">
          <h1>Proposta Comercial</h1>
          <h1>{fetchData.nomeDaEmpresa}</h1>
        </div>
        <div className="propositionProjectDetails">
          <h2>Projeto - Escopo</h2>
          <h4>Entregáveis:</h4>
          <ul>
            {entregaveis.map((entregavel) => <li>{entregavel}</li>)}
          </ul>
          <h4>Duração:</h4>
          <p>{`${fetchData.duracao} dias`}</p>
        </div>
        <div className="propositionProjectDetails">
          <h2>Precificação</h2>
          <p>{`1) Projeto completo: R$ ${fetchData.valor}`}</p>

          <h4>Condições:</h4>
          <p>{fetchData.condicoes}</p>
        </div>
      </div>
      <div className="propositionEndingDiv">
        <h1>{'Obrigado <3'}</h1>
        <img className="propositionKaizenLogo" src={KaizenGreenLogo} alt="Logo verde da Kaizen" />
      </div>
    </div>
  );
}

export default Proposition;
