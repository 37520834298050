/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import instagram from '../Media/instagram.svg';
import linkedin from '../Media/linkedin.svg';
import whatsapp from '../Media/whatsapp.svg';
import carbonext from '../Media/carbonext.webp';
import seloAdventures from '../Media/seloAdventures.webp';

function FooterComponent() {
  function handleSocialClick(whichSocial) {
    if (whichSocial === 'linkedin') {
      return window.open('https://www.linkedin.com/company/kaizen-performance-marketing', '_blank');
    }
    if (whichSocial === 'instagram') {
      return window.open('https://www.instagram.com/kaizenperformance.io/', '_blank');
    }
    if (whichSocial === 'whatsapp') {
      return window.open('https://api.whatsapp.com/send?carmelone=5592992288224&text=Oi%20jo%C3%A3o,%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20da%20Kaizen.', '_blank');
    }
    return '';
  }

  function handleCertificationClick(whichCertification) {
    if (whichCertification === 'carbonext') {
      return window.open('https://carbonext.com.br/', '_blank');
    }
    if (whichCertification === 'adventures') {
      return window.open('https://adventures.inc/', '_blank');
    }
    return '';
  }

  return (
    <div className="socialAndCertificationsDiv">
      <div className="socialDiv">
        <img loading="lazy" alt="Nosso Linkedin" src={linkedin} onClick={() => handleSocialClick('linkedin')} />
        <img loading="lazy" alt="Nosso Instagram" src={instagram} onClick={() => handleSocialClick('instagram')} />
        <img loading="lazy" alt="Nosso WhatsApp" src={whatsapp} onClick={() => handleSocialClick('whatsapp')} />
      </div>
      <p>CNPJ: 44.564.543/0001-07</p>
      <p>
        Copyright © 2022
        <span id="kaizenGreentext"> Kaizen Marketing Direto LTDA </span>
      </p>
      <p>All Rights Reserved</p>
      <div className="certificationsDiv">
        <img loading="lazy" alt="Certificado da Carbonext" id="carbonext" src={carbonext} onClick={() => handleCertificationClick('carbonext')} />
        <img loading="lazy" alt="Selo De Partner da Adventures Inc" id="adventures" src={seloAdventures} onClick={() => handleCertificationClick('adventures')} />
        <a href="https://painel.anamid.com.br/minha-pagina" id="link-anamid" target="_blank">
          <img alt="AnaMid" width="150" src="https://painel.anamid.com.br/selo.png" />
        </a>
      </div>
    </div>
  );
}

export default FooterComponent;
