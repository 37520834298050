import React, { Component } from 'react';
import MainPageContent from '../Components/MainPageContent';
import PageFirstContent from '../Components/MainPageFirstContent';
import PageHeader from '../Components/MainPageHeader';

// eslint-disable-next-line react/prefer-stateless-function
class MainPage extends Component {
  render() {
    return (
      <div className="mainDiv">
        <PageHeader />
        <PageFirstContent />
        <MainPageContent />
        {/*  <Form />  */}
      </div>
    );
  }
}

export default MainPage;
