/* eslint-disable max-len */
import React from 'react';
import './OpenHouseLandingPage.css';
import kaizenLogo from '../../../Media/OpenHouseLandingPage/KaizenWhiteLogo.svg';
import homeIcon from '../../../Media/OpenHouseLandingPage/homeIcon.svg';
import kato from '../../../Media/OpenHouseLandingPage/KatoHappy.svg';
import FooterComponent from '../../../Components/FooterComponent';

function ThankYouPage() {
  return (
    <div className="openHouseLandingPage">
      <div className="openHouseHeader">
        <div id="openhead">
          <img alt="Kaizen Performance" src={kaizenLogo} />
        </div>

        <a href="https://kaizenperformance.io/#/" className="textAndIcon">
          <p>Home</p>
          <img alt="" src={homeIcon} />
        </a>
      </div>

      <div style={{ marginBottom: '109px', marginTop: '192px' }} className="openHouseKato">
        <div id="katoThanks" className="katoContainer">
          <img alt="K_ato" src={kato} />
        </div>

        <div id="katoThanksText" className="katoTextContainer">
          <h2>Nos vemos lá ;)</h2>
          <p>
            Obrigado por responder. Nos vemos no dia 19/1 🐱
            <br />
            <a style={{ color: '#fff' }} href="https://kaizenperformance.io/#/lp/openhouse">Voltar para o convite</a>
          </p>
        </div>
      </div>

      <FooterComponent />
    </div>
  );
}

export default ThankYouPage;
