/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import KaizenLogo from '../Media/KaizenGreenLogo.svg';
import BtnComponent from './BtnComponent';

function PageHeader() {
  function handleBlogClick() {
    return window.open('https://blog.kaizenperformance.io/', '_blank');
  }

  function handleImageClick() {
    return window.open('https://kaizenperformance.io/', '_blank');
  }

  return (
    <header className="header">
      <img loading="lazy" onClick={() => handleImageClick()} className="headerImage" src={KaizenLogo} alt="Logo da Kaizen" />
      <div className="headerSide">
        <span onClick={handleBlogClick} role="link" tabIndex={0} className="blogHeaderText">Blog</span>
        <BtnComponent />
      </div>
    </header>
  );
}

export default PageHeader;
