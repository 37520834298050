/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Redirect } from 'react-router';

function CreatePropositionForm() {
  const [redirect, changeRedirect] = useState(false);
  const [companyName, changeCompanyName] = useState('');
  const [requirements, changeRequirements] = useState('');
  const [duration, changeDuration] = useState(0);
  const [propositionValue, changeValue] = useState('');
  const [conditions, changeConditions] = useState('');
  const [buttonState, changeButtonState] = useState(true);

  function enableButton() {
    if (companyName !== '' && requirements !== '' && duration !== 0 && propositionValue !== '' && conditions !== '') {
      return changeButtonState(false);
    }
    return changeButtonState(true);
  }

  function handleCompanyNameChange({ target: { value } }) {
    changeCompanyName(value);
    return enableButton();
  }

  function handleRequirementsChange({ target: { value } }) {
    changeRequirements(value);
    return enableButton();
  }

  function handleDurationChange({ target: { value } }) {
    changeDuration(value);
    return enableButton();
  }

  function handleValueChange({ target: { value } }) {
    changeValue(value);
    return enableButton();
  }

  function handleConditionsChange({ target: { value } }) {
    changeConditions(value);
    return enableButton();
  }

  async function handleClick() {
    const fetchData = {
      nomeDaEmpresa: companyName,
      entregaveis: requirements,
      duracao: duration,
      valor: propositionValue,
      condicoes: conditions,
    };
    await fetch('https://stark-ridge-24747.herokuapp.com/propositions', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(fetchData),
    });
    return changeRedirect(true);
  }

  if (redirect === true) {
    return <Redirect to="/propostas" />;
  }

  return (
    <div className="newPropositionForm">
      <label className="form-label">
        Nome da empresa
      </label>
      <input className="form-control" value={companyName} onChange={(e) => handleCompanyNameChange(e)} />
      <label className="form-label">
        Entregáveis (Preencher cada um com uma vírgula e um espaço após cada)
      </label>
      <textarea className="form-control" value={requirements} onChange={(e) => handleRequirementsChange(e)} />
      <label className="form-label">
        Duração (Apenas números)
      </label>
      <input type="number" className="form-control" value={duration} onChange={(e) => handleDurationChange(e)} />
      <label className="form-label">
        Valor
      </label>
      <input className="form-control" value={propositionValue} onChange={(e) => handleValueChange(e)} />
      <label className="form-label">
        Condições
      </label>
      <input className="form-control" value={conditions} onChange={(e) => handleConditionsChange(e)} />
      <br />
      <button className="registerPropositionBtn" type="button" disabled={buttonState} onClick={() => handleClick()}>
        Registrar Proposta
      </button>
    </div>
  );
}

export default CreatePropositionForm;
