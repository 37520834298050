/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';
import KaizenLogo from '../../../Media/KaizenGreenLogo.svg';
import mudeMelhoreAprimore from '../../../Media/MmaLandingPage/mudeMelhoreAprimore.webp';
import joinha from '../../../Media/MmaLandingPage/joinha.svg';
import maleta from '../../../Media/MmaLandingPage/maleta.svg';
import pessoas from '../../../Media/MmaLandingPage/pessoas.svg';
import planilha from '../../../Media/MmaLandingPage/planilha.svg';
import verified from '../../../Media/MmaLandingPage/verified.svg';
import fametro from '../../../Media/Clients/fametro.svg';
import queiroz from '../../../Media/Clients/queiroz.svg';
import heineken from '../../../Media/MmaLandingPage/heineken.webp';
import bend from '../../../Media/MmaLandingPage/bend.webp';
import redBull from '../../../Media/MmaLandingPage/redBull.webp';
import carbonext from '../../../Media/carbonext.webp';
import adventures from '../../../Media/seloAdventures.webp';
import pipefy from '../../../Media/MmaLandingPage/pipefy.webp';
import pipedrive from '../../../Media/MmaLandingPage/pipedrive.webp';
import eSeNaoDerCerto from '../../../Media/MmaLandingPage/eSeNaoDerCerto.svg';
import instagram from '../../../Media/instagram.svg';
import linkedin from '../../../Media/linkedin.svg';
import whatsapp from '../../../Media/whatsapp.svg';
import './mmaLandingPage.css';

function mmaLandingPage() {
  function handleSocialClick(whichSocial) {
    if (whichSocial === 'linkedin') {
      return window.open('https://www.linkedin.com/company/kaizen-performance-marketing', '_blank');
    }
    if (whichSocial === 'instagram') {
      return window.open('https://www.instagram.com/kaizenperformance.io/', '_blank');
    }
    if (whichSocial === 'whatsapp') {
      return window.open('https://api.whatsapp.com/send?carmelone=5592992288224&text=Oi%20jo%C3%A3o,%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20da%20Kaizen.', '_blank');
    }
    return '';
  }

  function handleClick(e) {
    e.preventDefault();
    const form = document.getElementById('mmaForm');
    form.scrollIntoView();
  }

  return (
    <div className="mmaMainDiv">
      <header className="mmaHeader">
        <img alt="Kaizen Performance" src={KaizenLogo} />
        <button onClick={() => window.open('https://kaizenperformance.io/')} type="button">Ir para o site da Kaizen</button>
      </header>

      <div className="mmaFirstContent">
        <div className="textWrapper">
          <h1>Domine de vez a conquista de clientes todos os dias com o método MMA.</h1>

          <p>
            #MudeMelhoreAprimore
            <br />
            <br />
            👆🏽 Com foco em envolver você na busca pelos resultados, esse é o principal valor deste método.
            <br />
            <br />
            Continue lendo e entenda mais  👇
          </p>

          <a onClick={(e) => handleClick(e)}>
            <button className="blueButton" type="button">
              <strong>Comece Agora</strong>
            </button>
          </a>

        </div>

        <div className="imageWrapper">
          <img src={mudeMelhoreAprimore} alt="" />
        </div>
      </div>

      <div className="mmaRestOfContent">

        <div className="mmaSecondContent">
          <h2>Como vamos alavancar e ajudar sua empresa com o plano MMA?</h2>

          <div className="mmaSecondContentContainer">

            <div className="itemWrapper">
              <div className="titleWrapper">
                <img src={joinha} alt="" />
                <h3>Planejamento responsável</h3>
              </div>

              <p>Nosso planejamento tem um único foco. Ser alinhado às suas metas de negócio e aos seus resultados financeiros.</p>
            </div>

            <div className="itemWrapper">
              <div className="titleWrapper">
                <img src={maleta} alt="" />
                <h3>Gestão de Mídia</h3>
              </div>

              <p>Nos baseando nos dados, analisaremos a plataforma que faz mais sentido para seu negócio e a estratégia de anúncios que mais traz resultado.</p>
            </div>

            <div className="itemWrapper">
              <div className="titleWrapper">
                <img src={planilha} alt="" />
                <h3>Automatização de relatórios</h3>
              </div>

              <p>Nossos relatórios são automatizados e você sempre vai saber onde e como estamos investindo seu dinheiro.</p>
            </div>

            <div className="itemWrapper">
              <div className="titleWrapper">
                <img src={pessoas} alt="" />
                <h3>Acompanhamento próximo</h3>
              </div>
              <p>Não vamos te esquecer. Trabalhamos com reuniões quinzenais de acompanhamento para otimizar seus resultados.</p>
            </div>

          </div>

        </div>

        <div className="mmaThirdContent">
          <h2>Resultados que já obtemos:</h2>

          <div className="mmaThirdContentContainer">
            <div>
              <h2>
                + de
                <br />
                1.000.00,00
              </h2>
              <p>Reais gerenciados em mídia</p>
            </div>

            <div>
              <h2>
                + de
                <br />
                1 bilhão
              </h2>
              <p>De impressões nos anúncios</p>
            </div>

            <div>
              <h2>
                + de
                <br />
                25 clientes
              </h2>
              <p>No Amazonas, Brasil e no mundo.</p>
            </div>
          </div>
        </div>

        <div className="mmaFourthContent">
          <h2>Seu concorrente está anunciando e lucrando.<br /><br />
            E aí, vai continuar parado ou vamos levar sua empresa para outro nível?
          </h2>

          <div className="mmaWrapper">

            <p>
              <strong>
                Cansado de investir tempo e dinheiro em estratégias que não trazem nenhum retorno?
              </strong>
            </p>

            <ul>
              <li>Cansado de ver os outros terem resultados surpreendentes e você não?</li>
              <br />
              <li>Cansado de contratar serviços que você nem sequer tem participação e entendimento, e ser passado pra trás?</li>
              <br />
              <li>E se você pudesse tomar a frente na conquista de novos clientes, você faria isso?</li>
            </ul>

            <br />
            <p>
              <strong>
                Se sim, o método MMA da Kaizen é exatamente o que você precisa para alavancar seu negócio!
              </strong>
            </p>
            <br />
          </div>

          <div className="buttonWrapper">
            <a href="#mmaForm">
              <button className="blueButton" type="button">
                <strong>Comece Agora</strong>
              </button>
            </a>
            <p>Quero aplicar no meu négocio</p>
          </div>

        </div>

        <div className="mmaFifthContent">
          <h2>O que você ganha com esse método?</h2>

          <div className="mmaFifthContentContainer">
            <div>
              <img src={verified} alt=" ✔️ " />
              <p>Foco <strong>100% na performance </strong>e uma visão macro da estratégia nos seus anúncios</p>
            </div>
            <div>
              <img src={verified} alt=" ✔️ " />
              <p>Uma equipe que entende bem mais que o normal acerca de tráfego e <strong>vai te instruir durante o processo, </strong>ao invés de só fazer e você não entender nada</p>
            </div>
            <div>
              <img src={verified} alt=" ✔️ " />
              <p>Um <strong>serviço verdadeiro </strong>que traz resultados e não te engana com promessas mentirosas</p>
            </div>
            <div>
              <img src={verified} alt=" ✔️ " />
              <p>O movimento nos canais de tráfego dos seus clientes alavancados</p>
            </div>
            <div>
              <img src={verified} alt=" ✔️ " />
              <p><strong>Todos os clientes correndo atrás da sua empresa</strong></p>
            </div>
          </div>

          <div className="buttonWrapper">
            <a href="#mmaForm">
              <button type="button" className="blueButton">
                <strong>Comece Agora</strong>
              </button>
            </a>
            <p>Quero aplicar no meu négocio</p>
          </div>

        </div>

        <div className="mmaSixthContent">
          <h2>Empresas que confiam no nosso trabalho</h2>
          <div className="mmaSixthContentImagesContainer">
            <img className="img-1" src={fametro} alt="Fametro" />
            <img className="img-2" src={queiroz} alt="Queiroz" />
            <img className="img-3" src={redBull} alt="Red Bull" />
            <img className="img-4" src={bend} alt="Bend." />
            <img className="img-5" src={heineken} alt="Heineken." />
          </div>
        </div>

        <div className="mmaSeventhContent">
          <h2>Empresas que confiam no nosso trabalho</h2>
          <div className="mmaSeventhContentImagesContainer">
            <img className="img-1" src={adventures} alt="Fametro" />
            <img className="img-2" src={pipedrive} alt="Queiroz" />
            <img className="img-3" src={pipefy} alt="Red Bull" />
            <img className="img-4" src={carbonext} alt="Bend." />
          </div>
        </div>

        <div className="mmaEighthContent">
          <h2>E se não der certo?</h2>
          <div className="mmaEighthContentContainer">
            <img src={eSeNaoDerCerto} alt="" />

            <p>
              O método MMA foca em ter resultados em 3 meses (que é o tempo ideal para começar a fazer um bom trabalho), sendo o primeiro mês para teste, o segundo para otimização e, finalmente, o terceiro para conseguir escala!
              <br />
              <br />
              Por isso, caso chegue o terceiro mês e não haja escala, <strong>você é livre para escolher continuar ou não conosco.</strong>
            </p>
          </div>
        </div>

        <div id="mmaForm" className="mmaFormContainer">
          <h2>Fale com um de nossos especialistas!</h2>
          <p>Preencha o formulário e o nosso time entrará em contato</p>
          <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/ckqoettT90xwUFk7oB00Zwws7GvBb0N02rJwLmmyXoAPnrPuFRXKdOxxxKPo3Be1ij" />
        </div>

        <div style={{ marginBottom: '5%' }} className="socialAndCertificationsDiv">
          <div className="socialDiv">
            <img loading="lazy" alt="Nosso Linkedin" src={linkedin} onClick={() => handleSocialClick('linkedin')} />
            <img loading="lazy" alt="Nosso Instagram" src={instagram} onClick={() => handleSocialClick('instagram')} />
            <img loading="lazy" alt="Nosso WhatsApp" src={whatsapp} onClick={() => handleSocialClick('whatsapp')} />
          </div>
          <p>CNPJ: 44.564.543/0001-07</p>
          <p>
            Copyright © 2022
            <span id="kaizenGreentext"> Kaizen Marketing Direto LTDA </span>
          </p>
          <p>All Rights Reserved</p>
        </div>

      </div>
    </div>

  );
}

export default mmaLandingPage;
