/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-multi-str */
/* eslint-disable max-len */
import React from 'react';
import './partnersLandingPage.css';
import KaizenLogo from '../../../Media/KaizenGreenLogo.svg';
import AdventuresLogo from '../../../Media/PartnersLandingPage/adventuresLogo.webp';
import MarquinhosImage from '../../../Media/PartnersLandingPage/marquinhosImage.webp';
import ExternalLink from '../../../Media/PartnersLandingPage/externalLink.webp';
import ExternalLink2 from '../../../Media/PartnersLandingPage/externalLink2.webp';
import listening from '../../../Media/PartnersLandingPage/sendHelp.webp';
import PageHeader from '../../../Components/MainPageHeader';
import instagram from '../../../Media/instagram.svg';
import linkedin from '../../../Media/linkedin.svg';
import whatsapp from '../../../Media/whatsapp.svg';
import carbonext from '../../../Media/carbonext.webp';

let kaizenAdventures = <h2>Kaizen + Adventures Partners</h2>;

function PartnersLandingPage() {
  document.querySelector('meta[name="description"]').setAttribute('content', 'Veja aqui o que a Kaizen  preparou exclusivamente para os Partners da Adventures!');
  const width = window.innerWidth
  || document.documentElement.clientWidth || document.body.clientWidth;

  if (width < 1024) {
    kaizenAdventures = (
      <h2>
        Kaizen
        <br />
        +
        <br />
        Adventures Partners
      </h2>
    );
  }

  function handleSocialClick(whichSocial) {
    if (whichSocial === 'linkedin') {
      return window.open('https://www.linkedin.com/company/kaizen-performance-marketing', '_blank');
    }
    if (whichSocial === 'instagram') {
      return window.open('https://www.instagram.com/kaizenperformance.io/', '_blank');
    }
    if (whichSocial === 'whatsapp') {
      return window.open('https://api.whatsapp.com/send?carmelone=5592992288224&text=Oi%20jo%C3%A3o,%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20da%20Kaizen.', '_blank');
    }
    return '';
  }

  function handleCertificationClick(whichCertification) {
    if (whichCertification === 'carbonext') {
      return window.open('https://carbonext.com.br/', '_blank');
    }
    return '';
  }

  return (
    <div className="PartnersLandingPage">
      <PageHeader />
      {kaizenAdventures}
      <div className="kaizenAdventuresImagesContainer">
        <img loading="lazy" src={KaizenLogo} alt="Logo da Kaizen Performance" />
        <img loading="lazy" src={AdventuresLogo} alt="Logo da Adventures" />
      </div>
      <h3>Fala, Partner!</h3>
      <p id="marquinhos">
        <span className="blueText">
          Esperamos que nosso talk tenha ajudado em algum ponto.
        </span>
        <br />
        <span className="blueText">
          Vai aqui um resumo de tudo que você precisa saber sobre colocar seu site no modo Relâmpago Marquinhos ;)
        </span>
      </p>
      <img loading="lazy" id="marquinhosImage" src={MarquinhosImage} alt="Relâmpago Marquinhos" />
      <div className="bulletPoints">
        <li>Arquitetura de Informações</li>
        <p>As informações do seu site devem estar organizadas de maneira similar a um livro, tem que fazer sentido para o leitor (público-alvo).</p>

        <li>Métricas Visuais</li>
        <p>Não é o passo mais crucial no processo de fazer um bom site, mas sim, é algo importante, seu usuário toma decisões inconscientes baseadas apenas no design.</p>
        <div className="imageAndText">
          <img loading="lazy" src={ExternalLink} alt="externalLink" />
          <a onClick={(e) => { e.preventDefault(); window.open('https://www.visualeyes.design/'); }} href="">Clique aqui e veja a plataforma de testagem visual que utilizamos aqui na Kaizen.</a>
        </div>

        <li>Lighthouse Report</li>
        <p>Ferramenta gratuita do Google, lembre-se sempre de rodá-lo numa aba anônima e selecionar o desktop e mobile separadamente na hora de rodar o teste.</p>
        <div className="imageAndText">
          <img loading="lazy" src={ExternalLink} alt="externalLink" />
          <a onClick={(e) => { e.preventDefault(); window.open('https://developers.google.com/web/tools/lighthouse?hl=pt-br'); }} href="">Veja mais aqui.</a>
        </div>

        <li>Seguir as dicas do Google</li>
        <p>Follow the word!</p>

        <li>Otimizar Mídia do Site</li>
        <p>
          Lembre-se, menor tamanho de arquivos com a maior qualidade possível. Para imagens complexas use
          <span className="hardText"> WebP</span>
          , para vetores use
          <span className="hardText"> SVG</span>
          e para vídeos
          <span className="hardText"> MP4 H.264.</span>
        </p>
      </div>

      <div id="biggerImageAndText" className="imageAndText">
        <img loading="lazy" src={ExternalLink2} alt="externalLink" />
        <a href="" onClick={(e) => { e.preventDefault(); window.open('https://docs.google.com/presentation/d/1d1nbxY8L5yB_KrSKfkuzL2BGinhrszZvs80Jxr3PqEw/edit?usp=sharing'); }}>Veja a apresentação na íntegra aqui.</a>
      </div>

      <h3>
        Como podemos nos <span className="blueText">ajudar?</span>
      </h3>
      <img loading="lazy" id="listening" src={listening} alt="Please Send Help" />
      <div id="textToStart">
        <p className="textToStart">
          <span className="greenText">Sabia que as empresas que corrigem suas falhas de experiência do usuário tem 70% de chance de fidelizar o consumidor?</span>
          <br />
          E algumas correções podem ser feitas sem você precisar se descabelar. Mas por onde começar?
        </p>
        <p>A palavra da vez é <span className="greenText">experiência</span>!</p>
        <p>Nossa especialidade é essa.</p>
      </div>
      <h3><span className="blueText">Partners Pack</span></h3>
      <div id="textToStart">
        <p className="textToStart">
          Temos duas soluções para você, Partner da Adventures.
        </p>
        <div className="imageAndText">
          <img loading="lazy" src={ExternalLink} alt="externalLink" />
          <a onClick={(e) => { e.preventDefault(); window.open('https://docs.google.com/presentation/d/1IdgOksOF16Jy538fO17pyMgjg4Og68jn6YfYm09lBqQ/edit?usp=sharing'); }} href="">Veja aqui a apresentação que preparamos pra você</a>
        </div>
      </div>

      <div className="formDiv">
        <h3><span className="blackText">Seu site institucional com 20% de desconto</span></h3>
        <div id="formAndText">
          <p>
            Resolvemos tirar parte da nossa margem de lucro para contribuir com o ecossistema que tanto já nos ajudou. Preencha esse formulário e nossa equipe comercial irá entrar em contato com você.
          </p>
          <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/bXtL7kxJ7NpZmuczxAS1nLdxBUmu1KTxRREpaKBlurZjfXgYg5sUpxKmgYuaOjhJZh" />
        </div>
      </div>

      <div className="formDiv">
        <h3><span className="blackText">Viramos seu braço de tech</span></h3>
        <div id="formAndText">
          <p>
            Gostaríamos muito de ser seus parceiros no longo prazo, decidimos criar um programa de revshare (10%) para clientes indicados em conjunto.
            <br />
            <br />
            Tem clientes com demandas de software e você não consegue suprí-las? Vamos trabalhar juntos! Preencha esse formulário e entraremos em contato para crescer juntos!
          </p>
          <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/czAK2KiKPXBaZXqsugPMy9898fun1lVf80iDVc7rfeDvLgq3qxvGo2zJy0XmbLv4Er" />
        </div>
      </div>
      <div className="socialAndCertificationsDiv">
        <div className="socialDiv">
          <img loading="lazy" alt="Nosso Linkedin" src={linkedin} onClick={() => handleSocialClick('linkedin')} />
          <img loading="lazy" alt="Nosso Instagram" src={instagram} onClick={() => handleSocialClick('instagram')} />
          <img loading="lazy" alt="Nosso WhatsApp" src={whatsapp} onClick={() => handleSocialClick('whatsapp')} />
        </div>
        <p>CNPJ: 44.564.543/0001-07</p>
        <p>
          Copyright © 2022
          <span id="kaizenGreentext"> Kaizen Marketing Direto LTDA </span>
        </p>
        <p>All Rights Reserved</p>
        <div className="certificationsDiv">
          <img loading="lazy" alt="Certificado da Carbonext" id="carbonext" src={carbonext} onClick={() => handleCertificationClick('carbonext')} />
        </div>
      </div>
    </div>
  );
}

export default PartnersLandingPage;
