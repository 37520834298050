/* eslint-disable max-len */
import React from 'react';
import './OpenHouseLandingPage.css';
import kaizenLogo from '../../../Media/OpenHouseLandingPage/KaizenWhiteLogo.svg';
import homeIcon from '../../../Media/OpenHouseLandingPage/homeIcon.svg';
import kato from '../../../Media/OpenHouseLandingPage/Kato.svg';
import FooterComponent from '../../../Components/FooterComponent';
import kaizenIcon from '../../../Media/OpenHouseLandingPage/KaizenGreenLogo.svg';
import arrowIcon from '../../../Media/OpenHouseLandingPage/ArrowIcon.svg';
import plane from '../../../Media/OpenHouseLandingPage/plane.svg';

function OpenHouseLandingPage() {
  const [btnIcon, setBtnIcon] = React.useState(arrowIcon);

  return (
    <div className="openHouseLandingPage">

      <div className="openHouseHeader">
        <div id="openhead">
          <img alt="Kaizen Performance" src={kaizenLogo} />
        </div>

        <a href="https://kaizenperformance.io/#/" className="textAndIcon">
          <p>Home</p>
          <img alt="" src={homeIcon} />
        </a>
      </div>

      <div id="firstHouseOpen" style={{ marginTop: '4%', marginBottom: '6%' }} className="openHouseInvitation">
        <img alt="" src={kaizenIcon} />
        <h2>Open House</h2>
      </div>

      <div className="openHouseKato">
        <div className="katoContainer">
          <img alt="K_ato" src={kato} />
        </div>

        <div className="katoTextContainer">
          <h2>Olá ;)</h2>
          <p>
            Eu sou o K_ato e nós somos a Kaizen Perfomance.
            <br />
            <br />
            Primeiramente, obrigado por fazer parte - mesmo que ainda não conheça - do nosso universo, carinhosamente chamado de Kaizenverso.
            <br />
            <br />
            Estamos alçando voos mais longos e queremos você fazendo parte da nossa história. Venha para o OpenHouse do nosso novo escritório.
            <br />
            <br />
            Até te daria um spoiler de como o nosso espaço tá lindão, mas vou deixar a curiosidade no ar 🐱
          </p>
        </div>
      </div>

      <div className="openHouseInvitation">
        <p>
          <strong>
            Local:
          </strong>
          <br />
          The Place Business Center
          <br />
          Sala 801
          <br />
          Rua Belo Horizonte, 19
          <br />
          Manaus - AM
          <br />
          <br />
          <a href="https://www.google.com.br/maps/place/Kaizen+Performance/@-3.1061993,-60.0148117,17z/data=!3m1!4b1!4m5!3m4!1s0xadca89ba9df74509:0x6c964dd81f93bd!8m2!3d-3.1061993!4d-60.012623?coh=164777&entry=tt&shorturl=1">
            Clique aqui e veja a localização no Google.
          </a>
          <br />
          <br />
          Confirme sua presença clicando no botão
          abaixo.
          nos mandando uma mensagem
        </p>
        <button onMouseEnter={() => setBtnIcon(plane)} onMouseLeave={() => setBtnIcon(arrowIcon)} onClick={() => window.open('https://webforms.pipedrive.com/f/72rWBn3ngPRpDLkaYhmhnR60pP6ITQChz4POHqE1LaK5tMVLMugtiyJKQ39KrUtlwD')} type="button">
          <div />
          <div>
            <p>
              RSVP
            </p>
          </div>
          <div>
            <img alt="" src={btnIcon} />
          </div>
        </button>
      </div>

      <FooterComponent />
    </div>
  );
}

export default OpenHouseLandingPage;
