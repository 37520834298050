import React, { useState, useEffect } from 'react';

function AllPropositions() {
  const [endFetch, finishFetch] = useState(false);
  const [fetchData, updateFetchData] = useState([]);

  async function executeFetch() {
    const data = await fetch('https://stark-ridge-24747.herokuapp.com/propositions')
      .then((result) => result.json());
    updateFetchData(data);
    finishFetch(true);
    console.log(fetchData);
  }

  useEffect(() => {
    executeFetch();
  }, [endFetch]);

  function handleClick() {
    return window.open('https://kaizenperformance.io/#/propostas/criar', '_replace');
  }

  async function handleDeleteClick(id) {
    await fetch(`https://stark-ridge-24747.herokuapp.com/propositions/${id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    });
    await executeFetch();
  }

  async function handleViewClick(id) {
    return window.open(`https://kaizenperformance.io/#/propostas/${id}`, '_replace');
  }

  if (fetchData.length === 0) {
    return (
      <div className="allPropositionsDiv">
        <button className="createPropositionBtn" type="button" onClick={() => handleClick()}>Criar Nova Proposta</button>
      </div>
    );
  }

  return (
    <div className="allPropositionsDiv">
      <button className="createPropositionBtn" type="button" onClick={() => handleClick()}>Criar Nova Proposta</button>
      {fetchData.map((proposition) => (
        <div className="propositionCard">
          <h3>
            {proposition.nomeDaEmpresa}
          </h3>
          <button onClick={() => handleViewClick(proposition.id)} type="button">
            Ver Proposta
          </button>
          <button onClick={() => handleDeleteClick(proposition.id)} className="excludePropositionBtn" type="button">
            Excluir Proposta
          </button>
        </div>
      ))}
    </div>
  );
}

export default AllPropositions;
