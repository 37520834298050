/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Lottie from 'react-lottie';
import * as desenvolvimentoWeb from '../Media/desenvolvimentoWeb.json';
import * as copywriting from '../Media/copywriting.json';
import * as gestaoDeTrafego from '../Media/gestaoDeTrafego.json';
import KaizenMethod from '../Media/KaizenMethod.svg';
import fametro from '../Media/Clients/fametro.svg';
import saniteck from '../Media/Clients/saniteck.svg';
import amazon from '../Media/Clients/amazon.svg';
import bertolini from '../Media/Clients/bertolini.svg';
import carmel from '../Media/Clients/carmel.svg';
import siliconEnergy from '../Media/Clients/siliconEnergy.svg';
import peixoto from '../Media/Clients/peixoto.svg';
import queiroz from '../Media/Clients/queiroz.svg';
// import joao from '../Media/joao.webp';
// import jonathan from '../Media/jonathan.webp';
// import juliano from '../Media/juliano.webp';
import instagram from '../Media/instagram.svg';
import linkedin from '../Media/linkedin.svg';
import whatsapp from '../Media/whatsapp.svg';
import carbonext from '../Media/carbonext.webp';
import BtnComponent from './BtnComponent';
import seloAdventures from '../Media/seloAdventures.webp';
import FooterComponent from './FooterComponent';

function MainPageContent() {
  function handleSocialClick(whichSocial) {
    if (whichSocial === 'linkedin') {
      return window.open('https://www.linkedin.com/company/kaizen-performance-marketing', '_blank');
    }
    if (whichSocial === 'instagram') {
      return window.open('https://www.instagram.com/kaizenperformance.io/', '_blank');
    }
    if (whichSocial === 'whatsapp') {
      return window.open('https://api.whatsapp.com/send?carmelone=5592992288224&text=Oi%20jo%C3%A3o,%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20site%20da%20Kaizen.', '_blank');
    }
    return '';
  }

  function handleCertificationClick(whichCertification) {
    if (whichCertification === 'carbonext') {
      return window.open('https://carbonext.com.br/', '_blank');
    }
    if (whichCertification === 'adventures') {
      return window.open('https://adventures.inc/', '_blank');
    }
    return '';
  }

  // function handleTeamPersonClick(whichPerson) {
  //   if (whichPerson === 'joao') {
  //     return window.open('https://linktr.ee/joaokaizen', '_blank');
  //   }
  //   if (whichPerson === 'jonathan') {
  //     return window.open('https://linktr.ee/jonhreiss', '_blank');
  //   }
  //   if (whichPerson === 'juliano') {
  //     return window.open('http://linktr.ee/Julianokaizen', '_blank');
  //   }
  //   return '';
  // }
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    if (count === 0) {
      setCount(1);
    }
    if (count === 1) {
      new window.RDStationForms('formulario-padrao-da-kaizen-9626058b25f368262c29', 'UA-206681254-1').createForm();
      setCount(2);
    }
  });

  return (
    <div className="landingContent">
      <h2>Uma agência centrada em dados e performance</h2>
      <p>
        O que isso significa? Nossa missão principal é alcançar resultados
        com eficácia e fornecer aos nossos clientes um ótimo retorno sobre o investimento.
        Aproveitamos nossas mentes criativas e dados para criar estratégias
        de marketing digital que funcionam, com sucesso.
      </p>
      <h3>O método Kaizen</h3>
      <p>
        Após anos de testes e experiência com marketing digital,
        desenvolvemos uma estratégia de marketing de performance para posicionar
        sua empresa no mundo digital, gerando resultados. Analisaremos holisticamente seu
        negócio e desenvolveremos uma estratégia única de funil de tráfego
        pago, gerando visibilidade, marca e conversão.
      </p>
      <img loading="lazy" src={KaizenMethod} alt="Metódo Kaizen" className="kaizenMethod" />
      <h2>Serviços</h2>
      <div className="servicesDiv">
        <Lottie
          options={{
            loop: true, autoplay: true, animationData: gestaoDeTrafego, rendererSettings: {},
          }}
          height={100}
          width={100}
        />
        <h3>Gestão de Tráfego</h3>
        <p>Trabalhamos com anúncios no Google, Facebook, Youtube, Tiktok e Taboola.</p>
        <p>
          Usamos o método Kaizen para desenvolver holisticamente
          uma estratégia de marketing digital e anúncios que geram resultados.
        </p>
      </div>
      <div className="servicesDiv">
        <Lottie
          options={{
            loop: true, autoplay: true, animationData: copywriting, rendererSettings: {},
          }}
          height={100}
          width={100}
        />
        <h3>Copywriting</h3>
        <p>
          Uma parte muito importante do marketing digital é como a história é contada.
          O copywriting desempenha um papel essencial na transmissão da sua mensagem e
          no alcance dos resultados esperados. Temos grande especialidade neste assunto.
        </p>
      </div>
      <div className="servicesDiv">
        <Lottie
          options={{
            loop: true, autoplay: true, animationData: desenvolvimentoWeb, rendererSettings: {},
          }}
          height={100}
          width={100}
        />
        <h3>Desenvolvimento Web</h3>
        <p>
          Outro aspecto importante do marketing digital. Veja, por exemplo,
          nosso site e como ele o persuadiu a continuar lendo até agora.
          Queremos fazer o mesmo por você. Vamos criar landing pages,
          hotsites, plataformas de e-commerce e sites institucionais.
          O que você precisar colocar na internet.
          <br />
          <br />
          <a style={{ color: 'white' }} target="_blank" href="https://www.designrush.com/agency/software-development/trends/types-of-software-development">
            Conheça mais sobre desenvolvimento de Software
          </a>
        </p>
      </div>
      <BtnComponent />
      <div className="clientsDiv">
        <h2>
          Clientes
        </h2>
        <p>Alguns dos parceiros que confiam no nosso trabalho.</p>
        <div className="clientsImgDiv">
          <img loading="lazy" alt="Logo de um dos nossos clientes" src={saniteck} />
          <img loading="lazy" alt="Logo de um dos nossos clientes" src={siliconEnergy} />
          <img loading="lazy" alt="Logo de um dos nossos clientes" src={fametro} />
          <img loading="lazy" alt="Logo de um dos nossos clientes" src={amazon} />
          <img loading="lazy" alt="Logo de um dos nossos clientes" src={bertolini} />
          <img loading="lazy" alt="Logo de um dos nossos clientes" src={peixoto} />
          <img loading="lazy" alt="Logo de um dos nossos clientes" src={queiroz} />
          <img loading="lazy" alt="Logo de um dos nossos clientes" src={carmel} />
        </div>
      </div>
      {/* <div className="teamDiv">
        <h2>
          Nosso time
        </h2>
        <div className="teamSubDiv">
          <div onClick={() => handleTeamPersonClick('joao')} className="teamPerson">
            <img loading="lazy" alt="Foto do João Ricardo" src={joao} />
            <h3>João Ricardo</h3>
            <h4>Partner e fundador</h4>
            <p>
              Co-fundador, Web Designer, UX Designer, empresário e
              especialista em mercado internacional e vendas.
            </p>
          </div>
          <div onClick={() => handleTeamPersonClick('jonathan')} className="teamPerson">
            <img loading="lazy" alt="Foto do Jonathan Reis" id="jonathan" src={jonathan} />
            <h3>Jonathan Reis</h3>
            <h4>Partner e fundador</h4>
            <p>
              Co-fundador, empresário e gestor de tráfego
              especialista em Facebook Ads e Google Ads há mais de 3 anos
            </p>
          </div>
          <div onClick={() => handleTeamPersonClick('juliano')} className="teamPerson">
            <img loading="lazy" alt="Foto do Juliano Frota" src={juliano} />
            <h3>Juliano Frota</h3>
            <h4>Partner</h4>
            <p>
              Fundou 2 empresas. Advogado por formação. Fascinado por empresas e negócios.
            </p>
          </div>
        </div>
      </div> */}
      <div>
        <br />
        <br />
        <br />
        <h2>Entre em contato</h2>
      </div>
      <div className="contactDiv">
        <div className="contactInfo">
          <h2>
            Vamos conversar!
          </h2>
          <p>
            Insira suas informações aqui e nossa equipe entrará em contato com você!
          </p>
          <p>
            Você também pode enviar um e-mail direto para joao@kaizenperformance.io
          </p>
          <p>
            Estamos ansiosos para falar com você!
          </p>
        </div>
        <div className="contactForm" id="form">
          <div role="main" id="formulario-padrao-da-kaizen-9626058b25f368262c29" />
        </div>
      </div>
      <br />
      <br />
      <FooterComponent />
    </div>
  );
}

export default MainPageContent;
